import { React, memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineUser,
  AiOutlineTwitter,
  AiOutlineMail,
  AiOutlineShoppingCart,
  AiOutlineMenu,
  AiOutlinePhone
} from "react-icons/ai";
import './style.scss';
import { formatter } from 'utils/formatter';
import { ROUTERS } from "utils/router";

const Header = () => {

  const [isShowCategories, setShowCategories] = useState(true);

  const [menus, setMenus] = useState([
    {
      name: 'Trang chủ',
      path: ROUTERS.USER.HOME,
    },
    {
      name: 'Cửa hàng',
      path: ROUTERS.USER.PRODUCTS,
    },
    {
      name: 'Sản phẩm',
      path: "",
      isShowSubmenu: false,
      child: [
        {
          name: 'Thịt',
          path: '',
        },
        {
          name: 'Rau củ',
          path: '',
        },
        {
          name: 'Thức ăn nhanh',
          path: '',
        }
      ]
    },
    {
      name: 'Bài viết',
      path: ROUTERS.USER.HOME,
    },
    {
      name: 'Liên hệ',
      path: ROUTERS.USER.HOME,
    },
  ])
  return (
    <>
      <div className='header__top'>
          <div className='container'>
            <div className='row'>
              <div className='col-6 header__top_left'>
                  <ul>
                    <li><AiOutlineMail />hello@gmail.com</li>
                    <li>Miễn phí ship đơn từ {formatter(200000)} </li>
                  </ul>
              </div>
              <div className='col-6 header__top_right'>
                  <ul>
                    <li>
                      <Link to={''}>
                        <AiOutlineFacebook />
                      </Link>
                    </li>
                    <li>
                      <Link to={''}>
                        <AiOutlineInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to={''}>
                        <AiOutlineLinkedin />
                      </Link>
                    </li>
                    <li>
                      <Link to={''}>
                        <AiOutlineTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to={''}>
                        <AiOutlineUser />
                        <span>Đăng Nhập</span>
                      </Link>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-3'>
            <div className='header__logo'>
              <h1>24h SHOP</h1>
            </div>
          </div>
          <div className='col-xl-6'>
            <nav className='header__menu'>
              <ul>
                {
                  menus?.map((menu, menuKey) => (
                    <li key={menuKey} className={menuKey === 0 ? "active" : ""}>
                      <Link to={menu?.path}>{menu?.name}</Link>
                      {
                        menu.child && (
                          <ul className='header__menu__dropdown'>
                            {
                              menu.child.map((childItem, childKey) => (
                            <li key={`${menuKey}-${childKey}`}>
                              <Link to={childItem.path}>{childItem.name}</Link>
                            </li>
                              ))}
                          </ul>
                        )
                      }
                    </li>
                  ))
                }
              </ul>
            </nav>
          </div>
          <div className='col-xl-3'>
            <div className='header__cart'>
                <div className='header__cart__price'>
                  <span>{formatter(10012300)}</span>
                </div>
                <ul>
                  <li>
                    <Link to="#">
                      <AiOutlineShoppingCart /> <span>5</span>
                    </Link>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row hero__categories_container'>
          <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'>
            <div className='hero__categories'>
              <div className='hero__categories__all' onClick={() => setShowCategories(!isShowCategories)}>
                <AiOutlineMenu />
                Danh sách sản phẩm
              </div>
                  {isShowCategories && (
                    <ul className={isShowCategories ? "" : "hidden"}>
                      <li>
                        <Link to={'#'}>Thịt tươi</Link>
                      </li>
                      <li>
                        <Link to={'#'}>Rau củ</Link>
                      </li>
                      <li>
                        <Link to={'#'}>Nước trái cây</Link>
                      </li>
                      <li>
                        <Link to={'#'}>Trái cây</Link>
                      </li>
                      <li>
                        <Link to={'#'}>Hải sản</Link>
                      </li>
                    </ul>
                  )}
            </div>
          </div>
          <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 hero__search_container'>
            <div className='hero__search'>
              <div className='hero__search__form'>
                <form>
                  <input type='text' name='' value='' placeholder='Bạn đang tìm gì ?'></input>
                  <button type='submit'>Tìm kiếm</button>
                </form>
              </div>
              <div className='hero__search__phone'>
                  <div className='hero__search__phone__icon'>
                    <AiOutlinePhone />
                  </div>
                  <div className='hero__search__phone__text'>
                    <p>0994.995.996</p>
                    <span>Hổ trợ 24/7</span>
                  </div>
              </div>
            </div>
            <div className='hero__item'>
              <div className='hero__text'>
                <span>Trái cây tươi</span>
                <h2>
                  Rau quả <br/>
                  sạch 100%
                </h2>
                <p>Miễn phí giao hàng tận nơi</p>
                <Link to='#' className='primary-btn'>
                Mua ngay</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    
  )
}

export default memo(Header);