import HomePage from "./pages/users/homePage";
import ProflePage from "./pages/users/proflePage";
import MasterLayout from "./pages/users/theme/masterLayout";
import { ROUTERS } from "./utils/router";
import { Routes, Route } from "react-router-dom";

const renderUserRouter =()=>{
    const userRouters = [
        {
            path:ROUTERS.USER.HOME,
            component: <HomePage></HomePage>
        },
        {
            path:ROUTERS.USER.PROFILES,
            component: <ProflePage></ProflePage>
        },
        // neu co nhieu duong dan thi them vao tuong tu ","
    ];
    return (
        <MasterLayout>
            <Routes>
                {userRouters.map((item, key) => (
                    <Route key={key} path={item.path} element={item.component}></Route>
                ))}
            </Routes>
        </MasterLayout>
      );
}

const RouterCustom = () => {
    return renderUserRouter();
  
};

export default RouterCustom;